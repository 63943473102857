import React from 'react';
import glonLogo from '../../assets/images/glon_logo.svg';
import styles from './MainView.module.scss';
import { Button } from '@mui/material';

export default function MainView() {
  return (
    <div className={styles.mainView}>
      <img src={glonLogo} />
      <h1>Glon has moved to a new domain!</h1>
      <p>
        Glon has recently moved to a new domain. While all of your data has been
        moved safely, you should know that in order to protect your privacy,
        your password will need to be reset.
      </p>
      <p>
        You can find the new version of glon accessible at:{' '}
        <a href={'https://spuc.glon.app/'}>https://spuc.glon.app/</a>. Please be
        sure to update your bookmark!
      </p>
      <Button
        variant="contained"
        onClick={() =>
          window
            .open(
              'https://spuc.glon.app/account/requestPasswordReset',
              '_blank',
            )
            .focus()
        }
      >
        Reset Password & Sign In
      </Button>
      <p>
        After resetting your password, you will be emailed a link to confirm
        your email. <span>Check your spam!</span>
      </p>
    </div>
  );
}
